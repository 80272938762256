<template>
  <b-overlay
    v-if="showOverlay"
    :show="showOverlay"
    class="container"
  />
  <layout v-else />
</template>

<script>
import Layout from '@/layouts/Layout.vue'

export default {
  name: 'App',
  components: {
    Layout,
  },
  data() {
    return {
      showOverlay: true,
    }
  },
  async mounted() {
    // Auth screens
    if (window.location.pathname === '/login' || window.location.pathname === '/callback') {
      this.showOverlay = false
      return
    }

    const res = await this.axios.get(
      `${process.env.VUE_APP_API_URL}/auth/`,
    )
    if (res.status === 200) this.showOverlay = false
  },
}
</script>

<style>
* {
  line-height: 1.7em;
}

:root {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root { font-family: InterVariable, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important; }
}

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

h4 {
  letter-spacing: -1px;
  font-weight: 400;
}

b {
  font-weight: 500 !important;
}

.table th {
  font-size: 13px;
  font-weight: 500 !important;
  line-height: 1.4em;
}

.table td {
  vertical-align: middle !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f2f4f8 !important;
}

.navbar-nav {
  font-size: 15px !important;
  font-weight: 400;
}

nav {
  border-radius: 0 0 5px 5px;
  background-color: #D1F2FD !important;
}

.god-mode {
  font-size: 13px;
  font-family: 'JetBrains Mono', monospace !important;
  font-weight: 500;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px;
  border-radius: 5px;
}

.god-mode a {
  color: #555;
}

.company-key {
  font-size: 13px;
  font-weight: 500;
  color: #222;
  font-family: 'JetBrains Mono', monospace !important;
}

.copy-company-key {
  border: 0;
  background: none;
}

.copy {
  color: #555;
}

.copy:hover {
  color: #FF9254;
}

.subscription-status {
  font-family: 'JetBrains Mono', monospace !important;
  text-transform: uppercase;
  font-size: 13px;
  margin-left: -21px;
}

.jv-code {
  font-family: 'JetBrains Mono', monospace !important;
  font-size: 13px;
  line-height: 1.7em;
}

.jv-container.jv-light .jv-key {
  color: #21272a !important;
}

input[type="checkbox"].custom-control-input {
  display: none;
}

</style>
