<template>
  <b-navbar
    toggleable="lg"
    variant="light"
    class="py-3 sticky-top"
  >
    <b-navbar-brand
      href="/"
      class="text-black"
    >
      <logo />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav>
        <b-nav-text class="ml-4">
          <b-link href="/sole-installs">
            Installs
          </b-link>
        </b-nav-text>

        <b-nav-text class="ml-4">
          <b-link href="/surveys">
            Cancel Requests
          </b-link>
        </b-nav-text>

        <b-nav-text class="ml-4">
          <b-link href="/feature-flags">
            Feature Flags
          </b-link>
        </b-nav-text>
        <b-nav-item-dropdown
          class="ml-3"
        >
          <template #button-content>
            <span class="nav-dropdown-color">More Menus</span>
          </template>
          <b-dropdown-item
            href="/missing-payments"
          >
            Missing Payments
          </b-dropdown-item>
          <b-dropdown-item href="/integrations">
            Integrations
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav
        v-if="displaySearch()"
        class="ml-auto"
      >
        <b-nav-form>
          <b-form-input
            v-model="term"
            size="sm"
            class="mr-sm-2"
            placeholder="Search"
          />
          <b-button
            size="sm"
            class="my-2 my-sm-0 mr-sm-2"
            type="submit"
            variant="primary"
            @click.prevent="basicSearch"
          >
            Basic Search
          </b-button>
          <b-button
            size="sm"
            class="my-2 my-sm-0 mr-sm-2"
            variant="primary"
            type="submit"
            @click.prevent="utmSearch"
          >
            UTM Search
          </b-button>
          <b-button
            v-b-modal.modal-advanced-search
            size="sm"
            variant="primary"
            class="my-2 my-sm-0"
          >
            Advanced Search
          </b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>

    <b-modal
      id="modal-advanced-search"
      title="Advanced Search"
      ok-title="Search"
      ok-only
      centered
      @ok="advancedSearch()"
    >
      <b-form-input
        v-model="companyName"
        placeholder="Company name"
        type="text"
        name="company_name"
      />
      <b-form-input
        v-model="companyEmail"
        class="mt-2"
        placeholder="Company email"
        type="email"
        name="company_email"
      />
      <b-form-input
        v-model="userName"
        class="mt-2"
        placeholder="User name"
        type="text"
        name="user_name"
      />
      <b-form-input
        v-model="userEmail"
        class="mt-2"
        placeholder="User email"
        type="email"
        name="user_email"
      />
    </b-modal>
  </b-navbar>
</template>

<script>
import Logo from '@/components/icons/Logo.vue'

export default {
  components: { Logo },
  data() {
    return {
      term: '',
      companyName: '',
      companyEmail: '',
      userName: '',
      userEmail: '',
    }
  },
  methods: {
    displaySearch() {
      return this.$route.name !== 'sole-installs'
    },

    basicSearch() {
      if (this.term) window.location = `/search?term=${this.term}`
    },

    utmSearch() {
      if (this.term) window.location = `/utms/search?term=${encodeURIComponent(this.term)}`
    },

    advancedSearch() {
      // Preparing parameters
      const params = []
      if (this.companyName !== '') params.push(['companyName', this.companyName])
      if (this.companyEmail !== '') params.push(['companyEmail', this.companyEmail])
      if (this.userName !== '') params.push(['userName', this.userName])
      if (this.userEmail !== '') params.push(['userEmail', this.userEmail])

      // Validate parameters (simple for now)
      if (params.length < 1) {
        alert('No value detected')
        return
      }

      // Create parameter url
      let paramUrl = '?'
      params.forEach(param => {
        paramUrl += `${param[0]}=${param[1]}&`
      })
      paramUrl += 'advanced=true'

      // Search
      window.location = `/search${paramUrl}`
    },
  },
}
</script>

<style scoped>
  .nav-dropdown-color {
    color: rgba(0, 0, 0, 0.9);
  }
</style>
